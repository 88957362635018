import Layout from "../components/layout"
import { graphql } from "gatsby"

export default Layout
export const pageQuery = graphql`query($slug: String!) {
  markdownRemark(fields: {slug: {eq: $slug}}) {
    id
    html
    frontmatter {
      title
      tags
      display
      allow_sound
      no_page_swipe
      mobile_view
      background_images {
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
      images {
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
      background_color
      background_style
      vangogh_object
      audio {
        publicURL
      }
      background_videos {
        publicURL
      }
      video_player {
        publicURL
      }
      pdf {
        publicURL
      }
    }
    fields {
      slug
      table
    }
  }
}`
